// i18next-extract-mark-ns-start news

import React, { FC } from 'react'
import { INewsRelatedArticle } from 'src/components/news/article/news-article-types'
import NewsRelatedArticle from 'src/components/news/article/NewsRelatedArticle'
import { useTranslation } from 'gatsby-plugin-react-i18next'

interface INewsRelatedArticlesBlockProps {
  items: INewsRelatedArticle[]
  className?: string
}

const NewsRelatedArticlesBlock: FC<INewsRelatedArticlesBlockProps> = ({
  className,
  items,
}) => {
  const { t } = useTranslation('news')
  return (
    <div className={className}>
      <h3 className="text-lg text-deepDarkBlue font-bold pb-2">
        {t('Related Articles')}
      </h3>
      {items.map((item, index) => (
        <NewsRelatedArticle
          key={item.id}
          title={item.title}
          slug={item.slug}
          className={`border-grayBlue border-b ${index ? '' : 'border-t'}`}
        />
      ))}
    </div>
  )
}

export default NewsRelatedArticlesBlock
