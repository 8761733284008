// i18next-extract-mark-ns-start news

import React, { FC } from 'react'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { INewsItem } from 'src/components/news/article/news-article-types'
import NewsArticleBody from 'src/components/news/article/NewsArticleBody'
import NewsArticleHeader from 'src/components/news/article/NewsArticleHeader'
import SemiRoundedButton from 'src/components/ui/SemiRoundedButton'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'
import Section from 'src/components/typography/Section'

interface INewsPageContext {
  pageContext: {
    newsItem: INewsItem
  }
}

const IndexPage: FC<INewsPageContext> = ({
  pageContext: {
    newsItem: { date, title, image, video, contentBlocks, locale },
  },
}) => {
  const { t } = useTranslation('news')
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout>
        <Section>
          <NewsArticleHeader
            date={date}
            title={title}
            image={image}
            video={video}
            locale={locale}
          />
          <div className="container mt-9">
            <NewsArticleBody contentBlocks={contentBlocks} />
            <div className="flex justify-center lg:justify-start">
              <Link to="/news">
                <SemiRoundedButton onClick={() => {}}>
                  {t('Back')}
                </SemiRoundedButton>
              </Link>
            </div>
          </div>
        </Section>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
