import React, { FC } from 'react'

interface INewsExternalVideoBlockProps {
  className?: string
  videoId: string
}

const NewsExternalVideoBlock: FC<INewsExternalVideoBlockProps> = ({
  videoId,
  className,
}) => (
  <div className={className}>
    <iframe
      className="w-full h-96"
      src={`https://www.youtube.com/embed/${videoId}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen=""
    />
  </div>
)

export default NewsExternalVideoBlock
