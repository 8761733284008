// i18next-extract-mark-ns-start news

import React, { FC } from 'react'
import articleSvg from 'src/images/icons/article.svg'
import rightArrowSvg from 'src/images/icons/right-arrow.svg'
import { useTranslation, Link } from 'gatsby-plugin-react-i18next'

interface INewsRelatedArticleProps {
  title: string
  slug: string
  className?: string
}

const NewsRelatedArticle: FC<INewsRelatedArticleProps> = ({
  title,
  slug,
  className,
}) => {
  const { t } = useTranslation('news')
  return (
    <div
      className={`flex justify-between py-4 leading-tight text-lg ${
        className || ''
      }`}
    >
      <div className="flex">
        <img src={articleSvg} className="self-center w-6 mr-3" alt="article" />
        <p className="self-center text-left">{title}</p>
      </div>
      <div className="flex ml-14 relative">
        <p className="self-center whitespace-nowrap mr-6 hidden lg:block">
          {t('See article')}
        </p>
        <img
          src={rightArrowSvg}
          className="w-7 lg:w-5 max-w-none self-center"
          alt={t(`See article`)}
        />
        <Link to={`/news/${slug}/`}>
          {/* see https://stackoverflow.com/a/3494108 */}
          {/* eslint-disable-next-line react/self-closing-comp */}
          <span className="absolute inset-0 z-10"></span>
        </Link>
      </div>
    </div>
  )
}

export default NewsRelatedArticle
