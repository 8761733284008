import React, { FC } from 'react'
import 'video-react/dist/video-react.css'
import { Player } from 'video-react'

interface INewsInternalVideoBlockProps {
  className?: string
  videoUrl: string
  posterUrl: string
}

const NewsInternalVideoBlock: FC<INewsInternalVideoBlockProps> = ({
  className,
  posterUrl,
  videoUrl,
}) => (
  <div className={`${className}`}>
    <Player poster={posterUrl}>
      <source src={videoUrl} />
    </Player>
  </div>
)

NewsInternalVideoBlock.defaultProps = {
  className: '',
}

export default NewsInternalVideoBlock
