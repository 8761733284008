import React, { FC, useEffect, useState } from 'react'
import IframeLoadingSpinner from 'src/components/ui/IframeLoadingSpinner'

interface INewsInternalVideoBlockProps {
  className?: string
  link: string
}

const NewsInternalVideoBlock: FC<INewsInternalVideoBlockProps> = ({
  className,
  link,
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const hideLoaderWhenLoaded = () => {
    const presentation = document.getElementById('presentation')

    if (!presentation) {
      return
    }

    presentation.addEventListener('load', () => {
      const checkerInLoop = setInterval(() => {
        if (!presentation.contentWindow) {
          clearInterval(checkerInLoop)
          return
        }
        const imagesInside = presentation.contentWindow.document.getElementsByClassName(
          'img-responsive'
        )

        if (imagesInside.length) {
          clearInterval(checkerInLoop)
          setIsLoading(false)
        }
      }, 500)
    })
  }

  useEffect(() => {
    if (process.env.NODE_ENV === `development`) {
      return
    }

    hideLoaderWhenLoaded()
  })

  const getContent = () => {
    if (process.env.NODE_ENV === `development`) {
      return (
        <p className="py-24 bg-paleBlue text-red text-center text-lg">
          Embed iframe will not work on local environment. Embed request needs
          to be redirected to the FIFA servers (it`s done in _redirects file -
          supported by netlify hosting)
        </p>
      )
    }

    return (
      <div
        style={{
          overflow: 'hidden',
          position: 'relative',
          height: 0,
          padding: '1px',
          paddingBottom: '56.25%',
        }}
        className="w-full mb-12"
      >
        <iframe
          id="presentation"
          style={{
            left: 0,
            top: 0,
            height: '100%',
            width: '100%',
            position: 'absolute',
          }}
          title="Law changes presentation"
          src={link}
          frameBorder="0"
          allow="fullscreen"
        />
        <IframeLoadingSpinner isLoading={isLoading} />
      </div>
    )
  }

  return <div className={`${className}`}>{getContent()}</div>
}

NewsInternalVideoBlock.defaultProps = {
  className: '',
}

export default NewsInternalVideoBlock
