export interface IMedia {
  url: string
  path: string
}

export function getUrl(media: IMedia) {
  return process.env.NODE_ENV === 'development' 
    ? media.url 
    : `/media${media.path}`
}

