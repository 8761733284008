import React, { FC } from 'react'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import { StyledListWrapper } from 'src/components/typography/List'

interface INewsHtmlBlockProps {
  html: string
  className?: string
}

const NewsHtmlBlock: FC<INewsHtmlBlockProps> = ({ html, className }) => (
  // eslint-disable-next-line react/no-danger
  <StyledListWrapper className={className}>
    <CmsHtmlContent dangerouslySetInnerHTML={{ __html: html }} />
  </StyledListWrapper>
)

export default NewsHtmlBlock
